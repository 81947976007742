import styled from "styled-components";
import React, { useRef, useState, useEffect } from 'react'
import { device } from '../models/device';
import { theme } from '../models/theme'
import PillButton from "../components/PillButton";
import HorizontalAspectRatioSlide from "../components/HorizontalAspectRatioSlide";
import HorizontalTeaserSlide from "../components/HorizontalTeaserSlide";
import HorizontalVideoSlide from "../components/HorizontalVideoSlide";
import { BackgroundSwiper } from "../components/BackgroundSwiper";
import HorizontalPreviewSlider from "../components/HorizontalPreviewSlider";
import { Link } from 'react-router-dom';


import hero0 from '../images/landing/hero/0_Fallout_Trailer_NoSound.mp4';
import hero0Preview from '../images/landing/hero/placeholder_hero_fallout.jpg';
import hero1 from '../images/landing/hero/1_Berlin_Trailer_NoSound_Blur.mp4';
import hero1Preview from '../images/landing/hero/1_Berlin_Trailer_NoSound_Blur.jpg';
import hero2 from '../images/landing/hero/2_Slumberland_Trailer_NoSound.mp4';
import hero2Preview from '../images/landing/hero/2_Slumberland_Trailer_NoSound.jpg';
import hero3 from '../images/landing/hero/3_AKA_Trailer_NoSound_Blur.mp4';
import hero3Preview from '../images/landing/hero/3_AKA_Trailer_NoSound_Blur.jpg';
import hero4 from '../images/landing/hero/4_Champion_Trailer_NoSound.mp4';
import hero4Preview from '../images/landing/hero/4_Champion_Trailer_NoSound.jpg';
import hero5 from '../images/landing/hero/5_Manifest_Trailer_NoSound.mp4';
import hero5Preview from '../images/landing/hero/5_Manifest_Trailer_NoSound.jpg';
import hero6 from '../images/landing/hero/6_Nadal_Trailer_NoSound.mp4';
import hero6Preview from '../images/landing/hero/6_Nadal_Trailer_NoSound.jpg';

import faceReenact from '../images/landing/1_faceReenactment_Demo.mp4';
import lipSync from '../images/landing/2_AILipsync_Demo_Blur.mp4';
import faceSwap from '../images/landing/3_faceSwap_Demo.mp4';
import deAging from '../images/landing/4_deAging_Demo.mp4';
import faceReenactThumb from '../images/landing/1_faceReenactment_Demo.jpg';
import lipSyncThumb from '../images/landing/2_AILipsync_Demo_Blur.jpg';
import faceSwapThumb from '../images/landing/3_faceSwap_Demo.jpg';
import deAgingThumb from '../images/landing/4_deAging_Demo.jpg';


import avatarNeo from '../images/landing/avatarNeo_Trailer.mp4';
import avatarNeoThumb from '../images/landing/avatarNeo_Trailer.jpg';

import genPerf from '../images/landing/1_AIGen_Performance_4k_Blur.mp4';
import chalScene from '../images/landing/2_MostChallengingScenesWithMesh_Blur.mp4';
import scaleProd from '../images/landing/3_ScalableProduction.mp4';

import genPerfThumb from '../images/landing/1_AIGen_Performance_4k_Blur.jpg';
import chalSceneThumb from '../images/landing/2_MostChallengingScenesWithMesh_Blur.jpg';
import scaleProdThumb from '../images/landing/3_ScalableProduction.jpg';


import innovation from '../images/landing/reseach_trailer.mp4'
import innovationThumb from '../images/landing/reseach_trailer.jpg'

import locations from '../images/landing/1_LA_AD_Company.jpg'

import nvidia from '../images/landing/nvidia.png'
import epic from '../images/landing/epic.png'
import plugandplay from '../images/landing/plugandplay.png'

import siggraphRTL from '../images/landing/siggraph.png'

import LogoButton from "../components/logoButton";
import ContentOverImage from "../components/ContentOverImage";
import ContentOverVideo from "../components/ContentOverVideo";

import { ReactComponent as NetflixLogo } from "../images/generic/logos/Netflix.svg";
import { ReactComponent as AmazonStudios } from "../images/generic/logos/AmazonStudios.svg";
import { ReactComponent as Miramax } from "../images/generic/logos/Miramax.svg";
import { ReactComponent as WarnerBros } from "../images/generic/logos/WarnerBros.svg";
import Darpa from "../images/generic/logos/Darpa.png";
import { ReactComponent as Adobe } from "../images/generic/logos/Adobe.svg";
import { ReactComponent as Apple } from "../images/generic/logos/Apple.svg";
import { ReactComponent as Google } from "../images/generic/logos/Google.svg";
import WEF from "../images/generic/logos/WEF.png";

import { SubTitleExtraPadding, RegularGapContentDiv, ContentSection, FullPageMarginlessContentSection, StyledPageDiv, StyledSpacerDiv, StyleSlide, Title, SubTitle, CoverImage, LogoSection, LogoSectionMobileVertical, GradientCoverTopBottom, GradientCoverTopBottomAbsolute } from '../general/GeneralStyles'
import AnimatedTypedText from "../components/AnimatedTypedText";
import MinimalVideo from "../components/MinimalVideo";
import HorizontalAspectRatioVideoSlide from "../components/HorizontalAspectRatioVideoSlide";

import VideoBackgroundDiv from "../components/VideoBackgroundDiv";

import {Helmet} from "react-helmet-async";


import ConnectorSVG from "../components/ConnectorSVG";
import YoutubePopup from "../components/YoutubePopup";

export const StyledContainerDiv = styled.div`
    pointer-events: none;
    display: flex;
    width: 1500px;
    height: 100%;
    justify-content: center;
    @media ${device.mobile} {
        flex-direction: column;
    }
`

export const StyledContentDivTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: ${props => props.theme.lightText};
    padding: 0 50px;
    width: 100%;
    z-index: 100;
    @media ${device.mobile} {
        min-height: 0;
        padding: 5vh 20px;
        margin-top: 20vh;
        width: auto;
        align-items: center;
        text-align: center;
        background: #00000080;
    }
`

export const StyledContentDivHoriz = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: auto;
    pointer-events: all;
    @media ${device.mobile} {
        width: 100%;
        filter: invert(1);
        flex-direction: column;
        justify-content: center;
    }
`

export const StyledContentDivVert = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 10%;
    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
`

export const RegularScrollDiv = styled.div`
    display: flex;
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: center;
`

export const RegularHeroDiv = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    background: ${props => props.background};
`

export const StyleSlideHidder = styled.div`
    width: 100%;
    height: auto;
    max-height: calc(100vh - 86px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 2rem;
`

export const TextArea = styled.div`
    width: 100%;
    height: auto;
`

export const LabeledImageSection = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 24px;
    padding: 0 0 2rem 0;
    box-sizing: border-box;
    @media ${device.mobile} {
        flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
        justify-content: flex-end;
        align-items: center;
    }
`

export const HoriztonalSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 12px;
`

export const HoriztonalSectionExtraMargin = styled(HoriztonalSection)`
    margin-bottom: 2rem;
    @media ${device.mobile} {
        margin-bottom: 0;
    }
`

export const LabeledImageContainer = styled.button`
    position: relative;
    height: auto;
    width: 50%;
    background: none;
    padding: 1rem;
    margin: 0;
    cursor: pointer;
    @media ${device.mobile} {
        width: 100%;
    }
`

export const SideTextContainer = styled.div`
    position: relative;
    height: auto;
    width: 50%;
    @media ${device.mobile} {
        width: 100%;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: ${props => props.leftAlign ? 'start' : 'end'};
        width: auto;
        padding: 1rem;
        @media ${device.mobile} {
            align-items: center;
        }
        li {
            display: flex;
            flex-direction: row;
            justify-content: ${props => props.leftAlign ? 'flex-start' : 'flex-end'};
            align-items: center;
            text-align: center;
            p {
                padding: 1rem;
                @media ${device.mobile} {
                    margin-block: 0.5rem;
                }
            }
            @media ${device.mobile} {
                justify-content: center;
            }
        }
        li::marker {

        }
    }
`
export const ImageLabelContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
`

export const ImageLabel = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 6px;
    background: ${props => props.hover ? props.theme.buttonHoverTransparent : props.theme.buttonTransparent};
    backdrop-filter: blur(10px);
    overflow: hidden;
    transition: all ease 0.1s;
    border-radius: ${props => props.hover ? '2rem' : 0};
    p {
        padding: ${props => props.hover ? '0.5rem' : 0};
        margin: 0;
        width: auto;
        display: inline;
        transition: all ease 0.2s;
    }
`

export const FullViewImageContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;

    @media ${device.mobile} {
        width: 90%;
    }
`

export const FullViewContentCover = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.ShadeCover};

    @media ${device.mobile} {
        h1 {
            margin: 0;
        }

        p {
            margin: 0;
        }
    }
`

export const CoverTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2rem;
    margin: 2rem;
    background: ${props => props.theme.panelTransparentDark};
    backdrop-filter: blur(10px);
    border-radius: ${props => props.theme.borderRadius};
    gap: 1rem;
    p {
        width: auto;
        display: inline;
        margin-block: 0;
    }

    @media ${device.mobile} {
        margin: 0 0 1rem 0;
        padding: 2rem 0 0 0;
    }
`

export const HoriztonalFeatureSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    padding: 2rem 48px 0 48px;

    @media ${device.mobile} {
        flex-direction: column-reverse;
    }
`

export const LeftTextRegion = styled.div`
    width: 30%;
    height: auto;

    p, h3 {
        text-align: left;
    }

    @media ${device.mobile} {
        width: 100%;
            p, h3 {
                text-align: center;
            }
    }
`

export const RightSwiperRegion = styled.div`
    width: 50%;
    height: auto;
    @media ${device.mobile} {
        width: 100%;
    }
`
export const FullViewContentCoverDark = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.ShadeCoverDark};
`

export const MarginContainer = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
`

export const TextRegion = styled.div`
    width: 50%;

    p {
        margin-block-end: 0;
    }
    @media ${device.mobile} {
        width: 100%;

        p {
            margin: 0 0 6px 0;
        }
    }
`

export const WhoWeAreSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: calc(75vh - 86px);

    @media ${device.mobile} {
        height: auto;
    }
`

export const CenterDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CenterDivTech = styled(CenterDiv)`
    p {
        margin-block-end: 0;
    }
`

export const MarginContainerTech = styled.div`
    width: 100%;
    margin: 2rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    @media ${device.mobile} {
        justify-content: center;
    }
`

export const AnimP = styled.p`
    text-shadow: 0 0  ${props => props.hover ? '1rem' : '0px'} ${props => props.theme.textLight};
    transition: all ease 0.1s;
`

export const ExtraMarginH1 = styled.h1`
    margin-block-end: 3rem;
`

const HeroItems = [
    {
      title: 'Fallout',
      subtitle: 'AI Face Swap & De-Aging',
      source: hero0,
      previewSource: hero0Preview,
    },
    {
        title: 'Feature TV Series (2023)',
        subtitle: 'AI Lip Sync VFX',
        source: hero1,
        previewSource: hero1Preview,
      },
      {
        title: 'Slumberland',
        subtitle: 'Facial Reenactment VFX',
        source: hero2,
        previewSource: hero2Preview,
      },
      {
        title: 'Feature Film (2023)',
        subtitle: 'AI Lip Sync VFX',
        source: hero3,
        previewSource: hero3Preview,
      },
      {
        title: 'The Champion',
        subtitle: 'AI Lip Sync VFX',
        source: hero4,
        previewSource: hero4Preview,
      },
      {
        title: 'Manifest',
        subtitle: 'AI Face Swap',
        source: hero5,
        previewSource: hero5Preview,
      },
];

function TopHero() {
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <StyleSlideHidder>
            <HorizontalPreviewSlider onSlideChange={(swiper) => {console.log("active swiper index:" + swiper.realIndex); setActiveIndex(swiper.realIndex);}} swiperInit={setSwiper} setHeight={'calc( min(100vh - 86px , 165vw))'}>
                {
                    HeroItems.map((item, index) =>
                    <StyleSlide>
                        <HorizontalVideoSlide isActive={swiper ? swiper.realIndex==index : false} onEnded={() => {swiper.slideNext()}} playing={(activeIndex == index) ? true : false} source={item.source} previewImage={item.previewSource}>
                        <Title>
                            {item.title}
                        </Title>
                            <SubTitleExtraPadding>
                                {item.subtitle}
                            </SubTitleExtraPadding>
                        </HorizontalVideoSlide>
                    </StyleSlide>
                    )
                }
            </HorizontalPreviewSlider>
        </StyleSlideHidder>
    )
}

const TechnologySlides = [
    {
        title: 'Designed for Maximum Scale and Automation',
        description: 'Our scalable and highly automated process can produce up to 1000 shots per month while being up to 100X cheaper than traditional VFX.',
        source: scaleProd,
        preview: scaleProdThumb,
    },
    {
        title: 'AI Generated Performances in 4K',
        description: 'We process and deliver our VFX shots in 4k UHD HDR. Our technology generates high resolution pore detail, consistent teeth, and complex lip deformations for extreme close ups.',
        source: genPerf,
        preview: genPerfThumb,
    },
    {
        title: 'Built For the Most Challenging Scenes',
        description: 'We can handle the most complex shots and action scenes for generative AI (harsh lighting, side views, out of focus, motion blur, occlusions).',
        source: chalScene,
        preview: chalSceneThumb,
    },
];

export default function Home(props) {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [graphicSlide, setGraphicSlide] = React.useState(0);
    const animatedHeader = useRef();
    const animatedParagraph = useRef();

    const [hoverServicesOne, setHoverServicesOne] = useState(false);
    const [hoverServicesTwo, setHoverServicesTwo] = useState(false);
    const [hoverServicesThree, setHoverServicesThree] = useState(false);
    const [hoverServicesFour, setHoverServicesFour] = useState(false);
    
    const [screenPositionServicesOne, setScreenPositionServicesOne] = useState(0);
    const [screenPositionServicesTwo, setScreenPositionServicesTwo] = useState(0);
    const [screenPositionServicesThree, setScreenPositionServicesThree] = useState(0);
    const [screenPositionServicesFour, setScreenPositionServicesFour] = useState(0);

    const servicesOneRef = useRef(null);
    const servicesTwoRef = useRef(null);
    const servicesThreeRef = useRef(null);
    const servicesFourRef = useRef(null);

    const [isOpenServicesOne, setIsOpenServicesOne] = useState(false);
    const [isOpenServicesTwo, setIsOpenServicesTwo] = useState(false);
    const [isOpenServicesThree, setIsOpenServicesThree] = useState(false);
    const [isOpenServicesFour, setIsOpenServicesFour] = useState(false);

    const [isAvatarPopupOpen, setIsAvatarPopupOpen] = useState(false);

    useEffect(() => {
        // for resize handling
        function handleResize() {
            setDimensions({
              height: window.innerHeight ,
              width: window.innerWidth 
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        function handleScroll() {
            if(servicesOneRef.current != null){
                let rect = servicesOneRef.current.getBoundingClientRect();
                setScreenPositionServicesOne(Math.abs(((rect.y + rect.bottom)/2.0) - (dimensions.height/2.0)) / (dimensions.height/2.0));
            }
            if(servicesTwoRef.current != null){
                let rect = servicesTwoRef.current.getBoundingClientRect();
                setScreenPositionServicesTwo(Math.abs(((rect.y + rect.bottom)/2.0) - (dimensions.height/2.0)) / (dimensions.height/2.0));
            }
            if(servicesThreeRef.current != null){
                let rect = servicesThreeRef.current.getBoundingClientRect();
                setScreenPositionServicesThree(Math.abs(((rect.y + rect.bottom)/2.0) - (dimensions.height/2.0)) / (dimensions.height/2.0));
            }
            if(servicesFourRef.current != null){
                let rect = servicesFourRef.current.getBoundingClientRect();
                setScreenPositionServicesFour(Math.abs(((rect.y + rect.bottom)/2.0) - (dimensions.height/2.0)) / (dimensions.height/2.0));
            }
        }
        
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);

        }
    }, []);

    return (
        <StyledPageDiv>
            <Helmet>
                <title>Pinscreen</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Pinscreen" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <YoutubePopup youtubeLink='https://www.youtube.com/embed/SUYcTjre0Vg?si=WGvQsd-R61mnpxpM' onClose={() => {setIsAvatarPopupOpen(false)}} isOpen={isAvatarPopupOpen}/>
            <StyledSpacerDiv/>
                <RegularHeroDiv background={'#000'}>
                    <TopHero/>
                </RegularHeroDiv>
                <RegularGapContentDiv background={'#000'}>
                    <ContentSection>
                        <TextArea>
                            <h1>
                                Unparalleled 4K Generative AI for VFX
                            </h1>
                            <p>
                                We build the most advanced and scalable generative AI technology for visual effects and interactive experiences.<br/>
                                Our world-class team of researchers specialize in <Link to='#vfxSection'>visual dubbing</Link>, <Link to='#vfxSection'>face reenactment</Link>, <Link to='#vfxSection'>face swap</Link>, <Link to='#vfxSection'>aging/de-aging</Link>, and <Link to='#avatarSection'>avatar digitization</Link>.
                            </p>
                            <p>
                                Pinscreen is known for developing the world's first real-time deepfake technology and is the only company<br/>
                                which can visually dub entire feature films and TV shows – all in 4K UHD HDR.
                            </p>
                            <p>
                                Our customers include
                            </p>
                        </TextArea>
                        <LogoSection>
                            <LogoButton iconHeight={'1.75rem'}>
                                <NetflixLogo/>
                            </LogoButton>
                            <LogoButton iconHeight={'2rem'}>
                                <AmazonStudios/>
                            </LogoButton>
                            <LogoButton iconHeight={'1.5rem'}>
                                <Miramax/>
                            </LogoButton>
                            <LogoButton iconHeight={'2rem'}>
                                <WarnerBros/>
                            </LogoButton>
                            <LogoButton iconHeight={'2rem'}>
                                <Apple/>
                            </LogoButton>
                            <LogoButton iconHeight={'2rem'}>
                                <Google/>
                            </LogoButton>
                            <LogoButton iconHeight={'2.5rem'}>
                                <Adobe/>
                            </LogoButton>
                            <LogoButton iconHeight={'4rem'}>
                                <img src={Darpa}/>
                            </LogoButton>
                            <LogoButton iconHeight={'5rem'}>
                                <img src={WEF}/>
                            </LogoButton>
                        </LogoSection>
                    </ContentSection>
                    <ContentSection id='vfxSection'>
                        <YoutubePopup youtubeLink='https://www.youtube.com/embed/zytcLEDcFTs?si=naQ-dw189RFO--Ey' onClose={() => {setIsOpenServicesOne(false)}} isOpen={isOpenServicesOne}/>
                        <YoutubePopup youtubeLink='https://www.youtube.com/embed/5u1MZLa7bMA?si=xBU-ZAHWsg5gSWR9' onClose={() => {setIsOpenServicesTwo(false)}} isOpen={isOpenServicesTwo}/>
                        <YoutubePopup youtubeLink='https://www.youtube.com/embed/8Qn8Io3Z2Fc?si=Avp-_D5MmUPd4osq' onClose={() => {setIsOpenServicesThree(false)}} isOpen={isOpenServicesThree}/>
                        <YoutubePopup youtubeLink='https://www.youtube.com/embed/2BFMgykTCnM?si=QIJEatw7KqeW99W3' onClose={() => {setIsOpenServicesFour(false)}} isOpen={isOpenServicesFour}/>
                        <ExtraMarginH1>
                            From AI Lip Sync to Face Swaps and De-Aging
                        </ExtraMarginH1>
                        <LabeledImageSection ref={servicesOneRef} id="sectionOneParent">
                            {
                                dimensions.width <= 768 ?
                                <></>
                                :
                                <>
                                    <ConnectorSVG elementP="sectionOneParent" elementA="sectionOne" elementB="sectionOneParagraphOne" stroke={screenPositionServicesOne <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionOneParent" elementA="sectionOne" elementB="sectionOneParagraphTwo" stroke={screenPositionServicesOne <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionOneParent" elementA="sectionOne" elementB="sectionOneParagraphThree" stroke={screenPositionServicesOne <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                </>
                            }
                            <LabeledImageContainer id="sectionOne" onMouseEnter={() => {setHoverServicesOne(true)}} onMouseLeave={() => {setHoverServicesOne(false)}} onClick={() => {setIsOpenServicesOne(true)}}>
                                <ContentOverVideo borderRadius={theme.borderRadius} source={faceReenact} previewImage={faceReenactThumb} aspectRatio={"16/9"} videoBrightness='85'>
                                    <ImageLabelContainer>
                                        <ImageLabel hover={hoverServicesOne}>
                                            <AnimP hover={hoverServicesOne}>
                                                Face Reenactment Demo
                                            </AnimP>
                                        </ImageLabel>
                                    </ImageLabelContainer>
                                </ContentOverVideo>
                            </LabeledImageContainer>
                            <SideTextContainer leftAlign>
                                <ul>
                                    <li id="sectionOneParagraphOne">
                                        <p>
                                            Facial performance correction in postproduction
                                        </p>
                                    </li>
                                    <li id="sectionOneParagraphTwo">
                                        <p>
                                            Dialogue manipulation in existing footage
                                        </p>
                                    </li>
                                    <li id="sectionOneParagraphThree">
                                        <p>
                                            Full dialogue for babies & young children
                                        </p>
                                    </li>
                                </ul>
                            </SideTextContainer>
                        </LabeledImageSection>
                        <LabeledImageSection ref={servicesTwoRef} id="sectionTwoParent" reverse={true}>
                            {
                                dimensions.width <= 768 ?
                                <></>
                                :
                                <>
                                    <ConnectorSVG elementP="sectionTwoParent" elementB="sectionTwo" elementA="sectionTwoParagraphOne" reversedAnim stroke={screenPositionServicesTwo <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionTwoParent" elementB="sectionTwo" elementA="sectionTwoParagraphTwo" reversedAnim stroke={screenPositionServicesTwo <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionTwoParent" elementB="sectionTwo" elementA="sectionTwoParagraphThree" reversedAnim stroke={screenPositionServicesTwo <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                </>
                            }
                            <SideTextContainer>
                                <ul>
                                    <li id="sectionTwoParagraphOne">
                                        <p>
                                            Perfect Lip Sync for Dubbing into Any Language
                                        </p>
                                    </li>
                                    <li id="sectionTwoParagraphTwo">
                                        <p>
                                            Video-and Audio Based Lip Sync
                                        </p>
                                    </li>
                                    <li id="sectionTwoParagraphThree">
                                        <p>
                                            Reproducing Emotions and Non-Verbal Expressions
                                        </p>
                                    </li>
                                </ul>
                            </SideTextContainer>
                            <LabeledImageContainer id="sectionTwo" onMouseEnter={() => {setHoverServicesTwo(true)}} onMouseLeave={() => {setHoverServicesTwo(false)}} onClick={() => {setIsOpenServicesTwo(true)}}>
                                <ContentOverVideo borderRadius={theme.borderRadius} source={lipSync} previewImage={lipSyncThumb} aspectRatio={"16/9"} videoBrightness='85'>
                                    <ImageLabelContainer>
                                        <ImageLabel hover={hoverServicesTwo}>
                                            <AnimP hover={hoverServicesTwo}>
                                                AI Lip Sync Demo
                                            </AnimP>
                                        </ImageLabel>
                                    </ImageLabelContainer>
                                </ContentOverVideo>
                            </LabeledImageContainer>
                        </LabeledImageSection>
                        <LabeledImageSection ref={servicesThreeRef} id="sectionThreeParent">
                            {
                                dimensions.width <= 768 ?
                                <></>
                                :
                                <>
                                    <ConnectorSVG elementP="sectionThreeParent" elementA="sectionThree" elementB="sectionThreeParagraphOne" stroke={screenPositionServicesThree <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionThreeParent" elementA="sectionThree" elementB="sectionThreeParagraphTwo" stroke={screenPositionServicesThree <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionThreeParent" elementA="sectionThree" elementB="sectionThreeParagraphThree" stroke={screenPositionServicesThree <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                </>
                            }
                            <LabeledImageContainer id="sectionThree" onMouseEnter={() => {setHoverServicesThree(true)}} onMouseLeave={() => {setHoverServicesThree(false)}} onClick={() => {setIsOpenServicesThree(true)}}>
                                <ContentOverVideo borderRadius={theme.borderRadius} source={faceSwap} previewImage={faceSwapThumb} aspectRatio={"16/9"} videoBrightness='85'>
                                    <ImageLabelContainer>
                                        <ImageLabel hover={hoverServicesThree}>
                                            <AnimP hover={hoverServicesThree}>
                                                Face Swap Demo
                                            </AnimP>
                                        </ImageLabel>
                                    </ImageLabelContainer>
                                </ContentOverVideo>
                            </LabeledImageContainer>
                            <SideTextContainer leftAlign>
                                <ul>
                                    <li id="sectionThreeParagraphOne">
                                        <p>
                                            Face Replacement for Doubles and Stunt Actors
                                        </p>
                                    </li>
                                    <li id="sectionThreeParagraphTwo">
                                        <p>
                                            Bringing Back Younger Version of an Actor
                                        </p>
                                    </li>
                                    <li id="sectionThreeParagraphThree">
                                        <p>
                                            Reenacting Deceased or Injured People
                                        </p>
                                    </li>
                                </ul>
                            </SideTextContainer>
                        </LabeledImageSection>
                        <LabeledImageSection ref={servicesFourRef} id="sectionFourParent" reverse={true}>
                            {
                                dimensions.width <= 768 ?
                                <></>
                                :
                                <>
                                    <ConnectorSVG elementP="sectionFourParent" elementB="sectionFour" elementA="sectionFourParagraphOne" reversedAnim stroke={screenPositionServicesFour <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionFourParent" elementB="sectionFour" elementA="sectionFourParagraphTwo" reversedAnim stroke={screenPositionServicesFour <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                    <ConnectorSVG elementP="sectionFourParent" elementB="sectionFour" elementA="sectionFourParagraphThree" reversedAnim stroke={screenPositionServicesFour <= 0.5 ? theme.textLight : theme.buttonHover}/>
                                </>
                            }
                            <SideTextContainer>
                                <ul>
                                    <li id="sectionFourParagraphOne">
                                        <p>
                                            De-aging Appearance and Performance
                                        </p>
                                    </li>
                                    <li id="sectionFourParagraphTwo">
                                        <p>
                                            Target Age Specified by Reference Footages
                                        </p>
                                    </li>
                                    <li id="sectionFourParagraphThree">
                                        <p>
                                            Aging with Retained Likeness and Creative Direction from Client
                                        </p>
                                    </li>
                                </ul>
                            </SideTextContainer>
                            <LabeledImageContainer id="sectionFour" onMouseEnter={() => {setHoverServicesFour(true)}} onMouseLeave={() => {setHoverServicesFour(false)}} onClick={() => {setIsOpenServicesFour(true)}}>
                                <ContentOverVideo borderRadius={theme.borderRadius} source={deAging} previewImage={deAgingThumb} aspectRatio={"16/9"} videoBrightness='85'>
                                    <ImageLabelContainer>
                                        <ImageLabel hover={hoverServicesFour}>
                                            <AnimP hover={hoverServicesFour}>
                                                De-Aging Demo
                                            </AnimP>
                                        </ImageLabel>
                                    </ImageLabelContainer>
                                </ContentOverVideo>
                            </LabeledImageContainer>
                        </LabeledImageSection>
                        <HoriztonalSectionExtraMargin>
                            <Link to='/vfx-services'>
                                <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                            </Link>
                            <Link to='/vfx-projects'>
                                <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Our Projects"}/>
                            </Link>
                        </HoriztonalSectionExtraMargin>
                    </ContentSection>
                    <FullPageMarginlessContentSection id='avatarSection'>
                        <FullViewImageContainer>
                            {
                                dimensions.width <= 768 ?
                                <CenterDiv>
                                    <h1>
                                        Avatar Neo
                                    </h1>
                                    <MinimalVideo looping={true} playing={true} aspectRatio={"16/9"} source={avatarNeo} previewImage={avatarNeoThumb}/>
                                    <CoverTextContainer>
                                        <p>
                                            Whether for virtual production, game development or AR/VR experiences Pinscreen has you covered.<br/>
                                            <a href={"https://avatarneo.com/"}>Avatar Neo</a> is the easiest and fastest way to create a photoreal 3D avatar from a single photo.
                                        </p>
                                        <p>
                                            Simply upload a picture to generate your personalized avatar. We provide a SDK for Unreal and Unity but our API can be deployed anywhere!
                                        </p>
                                    </CoverTextContainer>
                                    <HoriztonalSection>
                                        <Link to='/avatar-neo-creator-app'>
                                            <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                                        </Link>
                                        <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Watch Demo"} handleClick={() => {setIsAvatarPopupOpen(true)}}/>
                                    </HoriztonalSection>
                                </CenterDiv>
                                :
                            <VideoBackgroundDiv playing={true} source={avatarNeo} previewImage={avatarNeoThumb} aspectRatio={"16/9"}>
                                <GradientCoverTopBottomAbsolute/>
                                    <FullViewContentCover>
                                        <h1>
                                            Avatar Neo
                                        </h1>
                                        <CoverTextContainer>
                                            <p>
                                                Whether for virtual production, game development or AR/VR experiences Pinscreen has you covered.<br/>
                                                <a href={"https://avatarneo.com/"}>Avatar Neo</a> is the easiest and fastest way to create a photoreal 3D avatar from a single photo.
                                            </p>
                                            <p>
                                                Simply upload a picture to generate your personalized avatar. We provide a SDK for Unreal and Unity but our API can be deployed anywhere!
                                            </p>
                                        </CoverTextContainer>
                                        <HoriztonalSection>
                                            <a href='https://avatarneo.com' target="_blank">
                                                <PillButton baseBackground={theme.buttonTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHoverTransparent} hoverColor={theme.textLight} value={"Learn More"}/>
                                            </a>
                                            <PillButton baseBackground={theme.buttonTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHoverTransparent} hoverColor={theme.textLight} value={"Watch Demo"} handleClick={() => {setIsAvatarPopupOpen(true)}}/>
                                        </HoriztonalSection>
                                    </FullViewContentCover>
                            </VideoBackgroundDiv>
                            }
                        </FullViewImageContainer>
                    </FullPageMarginlessContentSection>
                    <ContentSection>
                        <h1>
                            Cutting-Edge AI VFX Technologies
                        </h1>
                        <HoriztonalFeatureSection>
                            <LeftTextRegion>
                                <h3>
                                    <AnimatedTypedText useRef={animatedHeader}>
                                        { TechnologySlides[graphicSlide].title }
                                    </AnimatedTypedText>
                                </h3>
                                <p>
                                    <AnimatedTypedText useRef={animatedParagraph}>
                                        { TechnologySlides[graphicSlide].description}
                                    </AnimatedTypedText>
                                </p>
                                <MarginContainerTech>
                                    <Link to='/vfx-technology'>
                                        <PillButton baseBackground={theme.buttonHoverTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                                    </Link>
                                </MarginContainerTech>
                            </LeftTextRegion>
                            <RightSwiperRegion>
                                <HorizontalPreviewSlider onSlideChange={(swiper) => { setGraphicSlide((swiper.realIndex)) }}>
                                    {
                                        TechnologySlides.map(
                                            (item, index) =>
                                            <StyleSlide>
                                                <HorizontalAspectRatioVideoSlide isActive={graphicSlide == index} playing={true} aspectRatio={"16/9"} source={item.source} previewImage={item.previewSource}/>
                                            </StyleSlide>
                                        )
                                    }
                                </HorizontalPreviewSlider>
                            </RightSwiperRegion>
                        </HoriztonalFeatureSection>
                    </ContentSection>
                    <FullPageMarginlessContentSection>
                        <FullViewImageContainer>
                            {
                                dimensions.width <= 768 ?
                                <CenterDivTech>
                                    <h1>
                                        Breakthroughs Driven by State-of-the-Art Research
                                    </h1>
                                    <MinimalVideo looping={true} playing={true} aspectRatio={"16/9"} source={innovation} previewImage={innovationThumb}/>
                                    <p>
                                        Technological innovation is essential for bringing you the most advanced VFX solution in the industry. Our award-winning team of AI scientists are consistently publishing their research at top peer-reviewed venues in computer vision, machine learning, and computer graphics (CVPR, ICCV, ECCV, SIGGRAPH, NeurIPS, etc.) with over 50 publications, 7 patents, and exhibitions at the World Economic Forum and SIGGRAPH Real-Time Live!
                                    </p>
                                    <LogoSection>
                                        <LogoButton iconHeight={'8rem'}>
                                            <img src={siggraphRTL}/>
                                        </LogoButton>
                                    </LogoSection>
                                    <Link to='/vfx-research'>
                                        <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                                    </Link>
                                </CenterDivTech>
                                :
                            <ContentOverVideo source={innovation} previewImage={innovationThumb} aspectRatio={"16/9"}>
                                <FullViewContentCoverDark>
                                    <TextRegion>
                                        <h1>
                                            Breakthroughs Driven by State-of-the-Art Research
                                        </h1>
                                        <p>
                                            Technological innovation is essential for bringing you the most advanced VFX solution in the industry. Our award-winning team of AI scientists are consistently publishing their research at top peer-reviewed venues in computer vision, machine learning, and computer graphics (CVPR, ICCV, ECCV, SIGGRAPH, NeurIPS, etc.) with over 50 publications, 7 patents, and exhibitions at the World Economic Forum and SIGGRAPH Real-Time Live!
                                        </p>
                                    </TextRegion>
                                    <LogoSection>
                                        <LogoButton iconHeight={'8rem'}>
                                            <img src={siggraphRTL}/>
                                        </LogoButton>
                                    </LogoSection>
                                    <Link to='/vfx-research'>
                                        <PillButton baseBackground={theme.buttonHoverTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                                    </Link>
                                </FullViewContentCoverDark>
                            </ContentOverVideo>
                            }
                        </FullViewImageContainer>
                    </FullPageMarginlessContentSection>
                    <FullPageMarginlessContentSection>
                        <FullViewImageContainer>
                        {
                            dimensions.width <= 768 ?
                            <CenterDiv>
                                <WhoWeAreSection>
                                    <h1>
                                        Based in Hollywood, backed by Silicon Valley & UAE
                                    </h1>
                                    <CoverImage src={locations} aspectRatio={'16/9'}/>
                                    <p>
                                        Los Angeles | Abu Dhabi
                                    </p>
                                    <p>
                                        Pinscreen is an AI and VFX production company founded in 2015 by Hao Li, MIT TR35 winner and Professor of Computer Vision at MBZUAI. We are funded by top VCs in Silicon Valley and the UAE, with headquarters in Los Angeles and a branch in Abu Dhabi (UAE).
                                    </p>
                                    <LogoSectionMobileVertical>
                                        <LogoButton iconHeight={'6rem'}>
                                            <img src={nvidia}/>
                                        </LogoButton>
                                        <LogoButton iconHeight={'6rem'}>
                                            <img src={plugandplay}/>
                                        </LogoButton>
                                        <LogoButton iconHeight={'6rem'}>
                                            <img src={epic}/>
                                        </LogoButton>
                                    </LogoSectionMobileVertical>
                                    <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                                </WhoWeAreSection>
                            </CenterDiv>
                            :
                        <ContentOverImage source={locations}>
                            <FullViewContentCoverDark>
                                <MarginContainer>
                                    <h1>
                                        Based in Hollywood, backed by Silicon Valley & UAE
                                    </h1>
                                    <p>
                                        Los Angeles | Abu Dhabi
                                    </p>
                                    <p>
                                        Pinscreen is an AI and VFX production company founded in 2015 by <a href='http://www.hao-li.com/'>Hao Li</a>, MIT TR35 winner and Professor of Computer Vision at MBZUAI. We are funded by top VCs in Silicon Valley and the UAE, with headquarters in Los Angeles and a branch in Abu Dhabi (UAE).
                                    </p>
                                    <LogoSection>
                                        <LogoButton iconHeight={'6rem'}>
                                            <img src={nvidia}/>
                                        </LogoButton>
                                        <LogoButton iconHeight={'6rem'}>
                                            <img src={epic}/>
                                        </LogoButton>
                                        <LogoButton iconHeight={'6rem'}>
                                            <img src={plugandplay}/>
                                        </LogoButton>
                                    </LogoSection>
                                    <Link to='/about-us-company'>
                                        <PillButton baseBackground={theme.buttonHoverTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Learn More"}/>
                                    </Link>
                                </MarginContainer>
                            </FullViewContentCoverDark>
                        </ContentOverImage>
                        }
                        </FullViewImageContainer>
                    </FullPageMarginlessContentSection>
                </RegularGapContentDiv>
        </StyledPageDiv>
    )
}