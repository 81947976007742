import React, {useState} from 'react'
import styled from 'styled-components'
import { device } from '../models/device'
import { theme } from '../models/theme'
import { RegularContentDiv, RegularPaddinglessContentDiv, ContentSection, FullPageContentSection, FullPageMarginlessContentSection, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import UnderlineRadio from '../components/UnderlineRadio'
import placeholder from '../images/landing/placeholder_faceswap.png'

import pageBG1 from '../images/tech/Technology_1_4K_Blur.jpg'
import pageBG2 from '../images/tech/Technology_2_Robust_2_Blur.jpg'
import pageBG3 from '../images/tech/Technology_3_Controllable.jpg'
import pageBG4 from '../images/tech/Technology_4_Scalable.jpg'

import ImageBackgroundDiv from '../components/ImageBackgroundDiv'
import { Helmet } from 'react-helmet-async'

export const FullTopFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
`

export const TitleOverDiv = styled.div`
    position: absolute;

    h1 {
        position: relative;
       font-size: 2rem;
    }

    @media ${device.mobile} {
        position: relative;
    }
`

export const BottomAlignedSection = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    background-size: cover;
`

export const BottomAlignedMarginSection = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    z-index: 10;
    background: none;
`

export const BottomColumnSection = styled.div`
    height: auto;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    display: block;
    margin: 2rem 0 2rem 0;

    background: ${props => props.theme.panelTransparentDark};
    backdrop-filter: blur(10px);
    border-radius: ${props => props.theme.borderRadius};
    padding: 1rem;

    h1, h2, p {
        text-align: left;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-block: 0.5rem;
    }

    h2 {
        color: ${props => props.theme.accent};
        font-weight: 400;
    }

    p {
        @media ${device.mobile} {
            font-size: 1rem;
        }
    }
`

// export const ImageBehindDiv = styled.div`
//     position: absolute;
//     width: 100%;
//     height: ;
//     z-index: 0;
// `

// export const ImageBehind = styled.img`
//     position: relative;
//     width: 100%;
//     height: calc(100vh - 86);
//     aspect-ratio: ${props => props.aspectRatio};
//     object-fit: cover;
// `

export default function VFXTechnology() {
    return(
        <StyledPageDiv>
            <Helmet>
                <title>Technology</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Technology" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularPaddinglessContentDiv>
                <ImageBackgroundDiv source={pageBG1} brightness='0.9'>
                    <FullPageMarginlessContentSection>
                        <FullTopFlex>
                            <TitleOverDiv>
                                <h1>
                                    Technology
                                </h1>
                            </TitleOverDiv>
                                <BottomAlignedSection>
                                        <BottomAlignedMarginSection>
                                            <BottomColumnSection>
                                                <h2>
                                                    Fidelity and Resolution
                                                </h2>
                                                <h1>
                                                    Generating Every Facial Detail and Movement in 4K HDR
                                                </h1>
                                                <p>
                                                    In film production, every VFX shot needs to be produced at the highest quality and standard to deliver the most memorable and breathtaking
                                                    cinematic moments. Pinscreen’s video generation technology not only generates pore-level details and every wrinkle of the face in 4K
                                                    resolution, but also accurately reproduces complex face regions such as teeth and mouth interior. Facial expressions are temporal coherent
                                                    and free from artifacts, which is critical for accurate lips sync and faithfuly conveying emotions. Our solutions can handle extreme closeups and
                                                    arbitrary viewing angles.
                                                </p>
                                            </BottomColumnSection>
                                        </BottomAlignedMarginSection>
                                </BottomAlignedSection>
                        </FullTopFlex>
                    </FullPageMarginlessContentSection>
                </ImageBackgroundDiv>
                <ImageBackgroundDiv source={pageBG2} brightness='0.9'>
                    <FullPageMarginlessContentSection>
                        <FullTopFlex>
                            <BottomAlignedSection>
                                <BottomAlignedMarginSection>
                                    <BottomColumnSection>
                                        <h2>
                                            Robustness
                                        </h2>
                                        <h1>
                                            Handling the Most Complex Scenes in Film Production
                                        </h1>
                                        <p>
                                            From action movies to dramas, every scene is unique in terms of aesthetics, depth, and ambience, whether they are wide angle shots,
                                            extreme closeups, side-views, lens flares, or filmed under the most complex cinematic lightings. We do not impose any restrictions to the
                                            creative direction and process any film material that is already shot. We can produce convincing facial reenactment and face swapping on
                                            actors with any facial hair, wearing glasses, or even occluded by reflective surfaces such as windows. Without the need of additional training
                                            data, we can reproduce challenging lighting conditions faithfully on the generated faces.
                                        </p>
                                    </BottomColumnSection>
                                </BottomAlignedMarginSection>
                            </BottomAlignedSection>
                        </FullTopFlex>
                    </FullPageMarginlessContentSection>
                </ImageBackgroundDiv>
                <ImageBackgroundDiv source={pageBG3} brightness='0.8'>
                    <FullPageMarginlessContentSection>
                        <FullTopFlex>
                            <BottomAlignedSection>
                                <BottomAlignedMarginSection>
                                    <BottomColumnSection>
                                        <h2>
                                            Controllable Generative-AI
                                        </h2>
                                        <h1>
                                            We Support Art-Directable Controls for Any Creative Input
                                        </h1>
                                        <p>
                                            Generative-AI technologies produce photorealistic images by learning from real world photos and video footages, but fine-scale control is
                                            notoriously challenging when it comes to high-fidelity film material. Our neural rendering framework seamlessly integrates both high- and lowlevel
                                            geometric controls, giving our customers the ability to guide facial features and movements such as mouth movements, lip
                                            expressiveness, eye gaze, and overall head shapes.
                                        </p>
                                    </BottomColumnSection>
                                </BottomAlignedMarginSection>
                            </BottomAlignedSection>
                        </FullTopFlex>
                    </FullPageMarginlessContentSection>
                </ImageBackgroundDiv>
                <ImageBackgroundDiv source={pageBG4} brightness='0.8'>
                    <FullPageMarginlessContentSection>
                        <FullTopFlex>
                            <BottomAlignedSection>
                                <BottomAlignedMarginSection>
                                    <BottomColumnSection>
                                        <h2>
                                            Scalability & Automation
                                        </h2>
                                        <h1>
                                            We Process Every Shot of a Movie or even an entire TV Show
                                        </h1>
                                        <p>
                                            Our proprietary AI-driven visual effects production pipeline not only adopts the latest and most cutting-edge generative AI algorithms, but we
                                            also implement a highly optimized collaboration and production workflow, which is designed to scale with large volumes of distributed AI
                                            tasks in an VFX friendly environment. Our production platform integrates a range of artist-friendly 3rd party VFX software with our efficient task
                                            management and productivity solution, while ensuring seamless distributed computing across our own secure AI workstations and cloud
                                            computes. In short, we can guarantee the production of hundreds of VFX shots per month with a minimal team.
                                        </p>
                                    </BottomColumnSection>
                                </BottomAlignedMarginSection>
                            </BottomAlignedSection>
                        </FullTopFlex>
                    </FullPageMarginlessContentSection>
                </ImageBackgroundDiv>
            </RegularPaddinglessContentDiv>
        </StyledPageDiv>
    )
}