import dirtypop from '../images/projects/dirty-pop_Blur.jpg'
import indian2 from '../images/projects/indian-2.jpg'
import underparis from '../images/projects/under-paris_Blur.jpg'
import fallout from '../images/projects/fallout.jpg'
import berlin from '../images/projects/berlin-money-heist_Blur.jpg'
import kbc from '../images/projects/killer-book-club_Blur.jpg'
import aka from '../images/projects/aka_Blur.jpg'
import manifest from '../images/projects/Manifest-season-4-part-2.jpg'
import champion from '../images/projects/the-champion-of-auschwitz.jpg'
import neal from '../images/projects/neal-brennan-blocks_1.jpg'
import slumberland from '../images/projects/slumberland_1.jpg'
import champions from '../images/projects/champions-evolve.jpeg'
import detv from '../images/projects/DeTVKantineThumbnail.png'
import detv2 from '../images/projects/DeTVKantine.png'
import travis from '../images/projects/travis-waving-at-the-window_1.png'
import wef from '../images/projects/wef_thumb.jpg'
import wef2 from '../images/projects/wef_teaser.jpg'


export const Projects = [
  {
    url: 'dirty-pop',
    title: 'Feature TV Series',
    date: '2024',
    description: `Backstreet Boys. NSYNC. Lou Pearlman created the biggest boy bands of the '90s — and one of the largest Ponzi schemes in history. A riveting docuseries.`,
    tags: ['face_swap, voice_clone'],
    previewPic: dirtypop,
    bannerPic: dirtypop,
    youtubeLink: 'https://www.youtube.com/embed/MSQVWOvNwJw?si=dVL9feDYqs81iX2_',
    creditsTitle: {name:'Pinscreen', title:' Voice & Video Enhancing Technology'},
    credits: [],
    },
  {
    url: 'indian-2',
    title: 'Indian 2',
    date: '2024',
    description: 'Senapathy, an ex-freedom fighter turned vigilante who fights against corruption. Senapathy returns to the country to aid a young man who has been exposing corrupt politicians in the country through videos on the internet.',
    tags: ['face_swap'],
    previewPic: indian2,
    bannerPic: indian2,
    youtubeLink: 'https://www.youtube.com/embed/3bvBUT5pQYY?si=Sg6AEYS-nXOsUAJ0',
    credits: [{name:'Hao Li', title:'AI VFX Supervisor'}, {name:'Zimo Li', title:'Head of Production Engineering'}, {name:'Liwen Hu', title:'Head of R&D'}, {name:'Cosimo Wei', title:'Head of Technology'}, {name:'Aviral Agarwal', title:'Senior Technical Artist'}, {name:'Zejian Wang', title:'Senior R&D Engineer'}, {name:'Lain Goldwhite', title:'Senior Software Engineer'}, {name:'Tan Minh Dinh', title:'R&D Engineer'}, {name:'Brandon White', title:'Senior Digital Artist'}, {name:'Fernando De Avila', title:'Digital Artist'}, {name:'Luc Salles', title:'Digital Artist'}, {name:'Frances Chen', title:'Production Coordinator'}],
  },
  {
    url: 'under-paris',
    title: 'Feature Film',
    date: '2024',
    description: 'In order to save Paris from an international bloodbath, a grieving scientist is forced to face her tragic past when a giant shark appears in the Seine.',
    tags: ['lip_sync'],
    previewPic: underparis,
    bannerPic: underparis,
    youtubeLink: 'https://www.youtube.com/embed/jnCefPQIH98?si=UOKkOOnz-hGP5uCU',
    credits: [{name:'Hao Li', title:'VFX Supervisor'}, {name:'Zimo Li', title:'Head of Production Engineering'}, {name:'Liwen Hu', title:'Head of R&D'}, {name:'Cosimo Wei', title:'Head of Technology'}, {name:'Aviral Agarwal', title:'Senior Technical Artist'}, {name:'Zejian Wang', title:'Senior R&D Engineer'}, {name:'Lain Goldwhite', title:'Senior Software Engineer'}, {name:'Tan Minh Dinh', title:'R&D Engineer'}, {name:'Brandon White', title:'Senior Digital Artist'}, {name:'Fernando De Avila', title:'Digital Artist'}, {name:'Luc Salles', title:'Digital Artist'}, {name:'Frances Chen', title:'Production Coordinator'}],
  },
  {
    url: 'fallout-s1',
    title: 'Fallout',
    date: '2024',
    description: 'Based on one of the greatest video games of all time, Fallout is the story of haves and have-nots in a world in which there’s almost nothing left to have. 200 years after the apocalypse, the gentle denizens of luxury fallout shelters are forced to return to the incredibly complex, gleefully weird and highly violent universe waiting for them above.',
    tags: ['face_swap'],
    previewPic: fallout,
    bannerPic: fallout,
    youtubeLink: 'https://www.youtube.com/embed/V-mugKDQDlg?si=1N0pjXcgNIrg3xgE',
    creditsTitle: {name:'Pinscreen', title:'Visual Effects'},
    credits: [],  
  },
  {
    url: 'berlin-money-heist',
    title: 'Feature TV Series',
    date: '2023',
    description: 'Back to his golden age before the events of "Money Heist," Berlin and a masterful gang gather in Paris to plan one of his most ambitious robberies ever.',
    tags: ['lip_sync'],
    previewPic: berlin,
    bannerPic: berlin,
    youtubeLink: 'https://www.youtube.com/embed/ZONoMgeGAbI?si=Mx0estA2Th8Prg49',
    credits: [{name:'Hao Li', title:'VFX Supervisor'}, {name:'Zimo Li', title:'Head of Production Engineering'}, {name:'Liwen Hu', title:'Head of R&D'}, {name:'Cosimo Wei', title:'Head of Technology'}, {name:'Aviral Agarwal', title:'Senior Technical Artist'}, {name:'Zejian Wang', title:'Senior R&D Engineer'}, {name:'Lain Goldwhite', title:'Senior Software Engineer'}, {name:'Tan Minh Dinh', title:'R&D Engineer'}, {name:'Daniel Awerbuck', title:'Software Engineer'}, {name:'Brandon White', title:'Digital Artist'}, {name:'Fernando De Avila', title:'Digital Artist'}, {name:'Luc Salles', title:'Digital Artist'}, {name:'Frances Chen', title:'Production Coordinator'}],
  },
  {
    url: 'killer-book-club',
    title: 'Feature Film',
    date: '2023',
    description: 'Eight horror-loving friends fight for their lives when a killer clown who seems to know the grim secret they share begins to pick them off, one by one.',
    tags: ['lip_sync'],
    previewPic: kbc,
    bannerPic: kbc,
    youtubeLink: 'https://www.youtube.com/embed/XS3YztAj0-k?si=GrK0XljcjUhIjY0p',
    credits: [{name:'Hao Li', title:'VFX Supervisor'}, {name:'Zimo Li', title:'Head of Production Engineering'}, {name:'Liwen Hu', title:'Head of R&D'}, {name:'Zejian Wang', title:'Senior R&D Engineer'}, {name:'Tan Minh Dinh', title:'R&D Engineer'}, {name:'Cosimo Wei', title:'Head of Technology'}, {name:'Aviral Agarwal', title:'Senior Technical Artist'}, {name:'Brandon White', title:'Digital Artist'}, {name:'Frances Chen', title:'Production Coordinator'}, {name:'Daniel Awerbuck', title:'Junior Engineer'}],
  },
  {
    url: 'Champions-evolve-rafael-nadal',
    title: 'Champions Evolve – Rafael Nadal',
    date: '2023',
    description: 'A young Rafael Nadal plays tennis on a groundbreaking 3D billboard.',
    tags: ['face_swap'],
    previewPic: champions,
    bannerPic: champions,
    youtubeLink: 'https://www.youtube.com/embed/2558B6vUTvQ?si=doEncMYmSmNv91AA',
    creditsTitle: {name:'Pinscreen', title:'AI VFX'},
    credits: [],
  },
  {
    url: 'manifest',
    title: 'Manifest',
    date: '2023',
    description: 'When a plane mysteriously lands years after takeoff, the people onboard return to a world that has moved on without them and face strange new realities.',
    tags: ['face_swap', 'voice_clone'],
    previewPic: manifest,
    bannerPic: manifest,
    youtubeLink: 'https://www.youtube.com/embed/0wkMl-igRio?si=HDwY7LBYLqSQaP5a',
    creditsTitle: {name:'Pinscreen', title:'AI Visual Effects'},
    credits: [],
  },
  {
    url: 'aka',
    title: 'Feature Film',
    date: '2023',
    description: 'A steely special ops agent finds his morality put to the test when he infiltrates a crime syndicate and unexpectedly bonds with the boss\'s young son.',
    tags: ['lip_sync'],
    previewPic: aka,
    bannerPic: aka,
    youtubeLink: 'https://www.youtube.com/embed/044PUmZQd1g?si=k6De5HQftFOIrFNg',
    credits: [{name:'Hao Li', title:'VFX Supervisor'}, {name:'Zimo Li', title:'Head of Production Engineering'}, {name:'Liwen Hu', title:'Head of R&D'}, {name:'Zejian Wang', title:'Senior R&D Engineer'}, {name:'Tan Minh Dinh', title:'R&D Engineer'}, {name:'Cosimo Wei', title:'Head of Technology'},  {name:'Aviral Agarwal', title:'Senior Technical Artist'}, {name:'Brandon White', title:'Digital Artist'}, {name:'Frances Chen', title:'Production Coordinator'}, {name:'Daniel Awerbuck', title:'Junior Engineer'}],
  },
  {
    url: 'neal-brennan-blocks',
    title: 'Neal Brennan: Blocks',
    date: '2022',
    description: 'Neal Brennan talks about the emotional and cultural issues that make him feel like something\'s wrong with him in a humorous way, from drugs and alcohol, liberalism, to his small chances of finding real love.',
    tags: ['lip_sync'],
    previewPic: neal,
    bannerPic: neal,
    youtubeLink: 'https://www.youtube.com/embed/FHuHMwdHY8k?si=fEB6b_4h7NgBB-oK',
    credits: [{name:'Hao Li', title:'VFX Supervisor'}, {name:'Zimo Li', title:'R&D Engineer'}, {name:'Liwen Hu', title:'R&D Engineer'}, {name:'Zejian Wang', title:'R&D Engineer'}, {name:'Aviral Agarwal', title:'Senior Technical Artist'}, {name:'Brandon White', title:'Digital Animator'}],
  },
  {
    url: 'slumberland',
    title: 'Slumberland',
    date: '2022',
    description: 'A young girl discovers a secret map to the dreamworld of Slumberland, and with the help of an eccentric outlaw, she traverses dreams and flees nightmares, with the hope that she will be able to see her late father again.',
    tags: ['lip_sync'],
    previewPic: slumberland,
    bannerPic: slumberland,
    youtubeLink: 'https://www.youtube.com/embed/FBnkVJslRGo?si=dINBk3pfaEZHFAvq',
    creditsTitle: {name:'Pinscreen', title:'AI VFX'},
    credits: [{name:'Hao Li'}, {name:'Zimo Li'}, {name:'Liwen Hu'}, {name:'Aviral Agarwal'}, {name:'Brandon White'}, {name:'Zejian Wang'}, {name:'Cosimo Wei'}, {name:'Huiwen Luo'}, {name:'Anda Deng'}, {name:'Frances Chen'} ],
  },
  {
    url: 'the-champion-of-auschwitz',
    title: 'The Champion of Auschwitz',
    date: '2022',
    description: 'Sent to the Auschwitz concentration camp, a boxing champion fights for his life as he becomes a symbol of hope for his fellow prisoners.',
    tags: ['lip_sync'],
    previewPic: champion,
    bannerPic: champion,
    credits: [{name:'Hao Li', title:'AI VFX Supervisor'}, {name:'Zimo Li', title:'Senior Research Engineer'}, {name:'Cosimo Wei', title:'Senior Research Engineer'}, {name:'Liwen Hu', title:'Senior Research Engineer'}, {name:'Aviral Agarwal', title:'Senior Digital Artist'}, {name:'Zejian Wang', title:'Senior Research Engineer'}, {name:'Huiwen Luo', title:'Senior Research Engineer'}, {name:'Frances Chen', title:'Project Manager'}],
  },
  {
    url: 'travis-waving-at-the-window',
    title: 'Travis – Waving at the Window',
    date: '2021',
    description: 'The Official Video for Waving at the Window by Travis.',
    tags: ['face_swap'],
    previewPic: travis,
    bannerPic: travis,
    youtubeLink: 'https://www.youtube.com/embed/B5mzehiGZfo?si=Bz8ho97aohiyoiYp',
    creditsTitle: {name:'Pinscreen', title:'Deepfake VFX'},
    credits: [{name:'Hao Li'}, {name:'Liwen Hu'}, {name:'Cosimo Wei'}, {name:'Qingguo Xu'},],
  },
  {
    url: 'de-tv-kantine',
    title: 'De TV Kantine',
    date: '2021',
    description: 'A sketch show centered in a cafeteria starring multiple caricatures of Dutch celebrities.',
    tags: ['face_swap'],
    previewPic: detv,
    bannerPic: detv2,
    creditsTitle: {name:'Pinscreen', title:'AI VFX'},
    credits: [],
  },
  {
    url: 'world-economic-forum-deepfaked',
    title: 'World Economic Forum: Deepfaked',
    date: '2020',
    description: 'Hao Li explores the known and potential implications of this technology on society, and how we need to react.',
    tags: ['face_swap'],
    previewPic: wef,
    bannerPic: wef2,
    youtubeLink: 'https://www.youtube.com/embed/DZ_k70WKvlg?si=cdzrP2KEw6MDhnHT',
    creditsTitle: {name:'Pinscreen', title:'Deepfake Technology'},
    credits: [{name:'Hao Li'}, {name:'Jaewoo Seo'}, {name:'Koki Nagano'}, {name:'McLean Goldwhite'}, {name:'Huiwen Luo'}, {name:'Liwen Hu'}, {name:'Cosimo Wei'}, {name:'Frances Chen'},],
  },
];